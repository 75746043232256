

const accessControlProvider = {
    can: async ({ resource, action, params }: { resource?: any, action?: any, params?: any }) => {

        const adminFeatures = ['tenants','profiles'];
        const managerFeatures = ['clients'];

        const identity = JSON.parse(localStorage.getItem("profile") as string);
        
        if (action === "list"){
            if ((identity?.role !== "admin") && adminFeatures.includes(params?.resource.name)){
                return { can: false }
            } else {
                if (((identity?.role !== "manager") && (identity?.role !== "admin")) && managerFeatures.includes(params?.resource.name)){
                    return { can: false }
                } else {
                    return { can: true }
                }    
            }
        } else if (params?.resource.name == "Dashboard") {
            return { can: true };
        } else if ((action === "edit") || (action === "create") || (action === "delete") || (action === "show")) {
            return { can: true };
        } else {
            return {
                can: false,
                reason: "Unauthorized",
            };
        }
    },
    options: {
        buttons: {
            enableAccessControl: true,
            hideIfUnauthorized: false,
        },
    },
}

export default accessControlProvider;