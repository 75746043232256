import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useOne,
} from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  NumberField,
  MarkdownField,
  EmailField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

export const LoginsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      select:
        "id,Name,vendors(Name),clients(Name),Refresh,RefreshOffset,URI,Username,Password,Status,StatusMessage,Metadata,accounts(count)",
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const identity = JSON.parse(localStorage.getItem("profile") as string);

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          {translate("Logins.fields.Name")}
        </Typography>
        <TextField value={record?.Name} />
        {(identity.role === "manager" || identity.role === "admin") && (
          <>
            <Typography variant="body1" fontWeight="bold">
              {translate("Logins.fields.Client")}
            </Typography>
            <TextField value={record?.clients?.Name} />
          </>
        )}
        <Typography variant="body1" fontWeight="bold">
          {translate("Logins.fields.Vendors")}
        </Typography>
        <TextField value={record?.vendors?.Name} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Logins.fields.Status")}
        </Typography>
        <TextField value={record?.Status} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Logins.fields.StatusMessage")}
        </Typography>
        <TextField value={record?.StatusMessage} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Logins.fields.Refresh")}
        </Typography>
        <TextField value={record?.Refresh} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Logins.fields.RefreshOffset")}
        </Typography>
        <NumberField value={record?.RefreshOffset ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Logins.fields.URI")}
        </Typography>
        <MarkdownField value={record?.URI} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Logins.fields.Username")}
        </Typography>
        <EmailField value={record?.Username} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Logins.fields.Password")}
        </Typography>
        <TextField value={record?.Password} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Logins.fields.AccountsCount")}
        </Typography>
        <TextField value={record?.accounts[0].count} />
      </Stack>
    </Show>
  );
};
