import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useMany,
} from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  TagField,
  NumberField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export const VendorsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      select: "id,Name,Type,logins(id,Name),accounts(count)",
    },
  });

  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          {translate("Vendors.fields.Name")}
        </Typography>
        <TextField value={record?.Name} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Vendors.fields.Type")}
        </Typography>
        <TextField value={record?.Type} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Vendors.fields.AccountsCount")}
        </Typography>
        <TextField value={record?.accounts[0].count} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Vendors.fields.Logins")}
        </Typography>
        {record?.Logins?.length &&
          record?.logins?.map((item: any) => {
            return (
              <ListItem key={item.id}>
                <ListItemText
                  key={item.id}
                  primary={item.Name}
                  // secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
            );
          })}
      </Stack>
    </Show>
  );
};
