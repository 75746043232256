import React from "react";
import { Create } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import SelectField from "../../components/SelectField";
import AutoCompleteField from "../../components/AutoCompleteField";

import { statusOptions, invoiceSourceOptions } from "./config";

export const AccountsCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    control,
    formState: { errors },
  } = useForm();

  const AccountsData = queryResult?.data?.data;

  const identity = JSON.parse(localStorage.getItem("profile") as string);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("Name", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.Name}
          helperText={(errors as any)?.Name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Accounts.fields.Name")}
          name="Name"
        />
        {(identity.role === "manager" || identity.role === "admin") && (
          <AutoCompleteField // Not working yet.
            control={control}
            resource={"clients"}
            field={"clients_id"}
            name={"Name"}
            defaultValue={AccountsData?.clients_id}
            label={translate("Accounts.fields.Client")}
            error={!!(errors as any)?.Clients}
            helperText={(errors as any)?.Clients?.message}
          />
        )}
        <AutoCompleteField // Not working yet.
          id="vendors_id"
          control={control}
          resource="vendors"
          field="vendors_id"
          name="Name"
          defaultValue={AccountsData?.vendors_id}
          label={translate("Logins.fields.Vendor")}
          error={!!(errors as any)?.Vendors}
          helperText={(errors as any)?.Vendors?.message}
        />
        <SelectField
          id="Status"
          name="Status"
          label="Status"
          control={control}
          defaultValue={""}
          variant="outlined"
          helperText={(errors as any)?.Status?.message}
          margin="normal"
          error={!!(errors as any)?.Status}
          options={statusOptions}
        />
        <TextField
          {...register("StatusMessage")}
          error={!!(errors as any)?.StatusMessage}
          helperText={(errors as any)?.StatusMessage?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Accounts.fields.StatusMessage")}
          name="StatusMessage"
        />
        <SelectField
          id="InvoiceSource"
          name="InvoiceSource"
          label="InvoiceSource"
          control={control}
          defaultValue={""}
          variant={"outlined"}
          helperText={(errors as any)?.InvoiceSource?.message}
          margin="normal"
          error={!!(errors as any)?.InvoiceSource}
          options={invoiceSourceOptions}
        />
      </Box>
    </Create>
  );
};
