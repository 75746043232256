import React from "react";
import { Edit } from "@refinedev/mui";
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import {
  IResourceComponentsProps,
  useTranslate,
  useParsed,
} from "@refinedev/core";

import SelectField from "../../components/SelectField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AutoCompleteField from "../../components/AutoCompleteField";
import { FieldValues } from "react-hook-form";

import { supabaseClient } from "utility";

import { roleOptions } from "./config";

// https://roughlywritten.substack.com/p/supabase-multi-tenancy-simple-and
// https://supabase.com/docs/guides/database/postgres/triggers#example-trigger-function
// https://stackoverflow.com/questions/35568071/postgresql-insert-trigger-to-concatenate

export const ProfilesEdit: React.FC<IResourceComponentsProps> = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { queryResult, onFinish },
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const ProfilesData = queryResult?.data?.data;

  const { id: user_id } = useParsed();

  const onFinishHandler = (data: FieldValues) => {
    console.log("SUBMITTING DATA", data);

    const identity = JSON.parse(localStorage.getItem("profile") as string);

    console.log("ID is ", user_id);
    if (data.password !== "" && user_id) {
      console.log("Resetting password.");
      supabaseClient.auth.admin.updateUserById(user_id as string, {
        password: data.password,
        app_metadata: {
          ...identity.app_metadata,
          tenant_id: data.tenant_id,
        },
      });
    } else if (user_id) {
      supabaseClient.auth.admin.updateUserById(user_id as string, {
        app_metadata: {
          ...identity.app_metadata,
          tenant_id: data.tenant_id,
        },
      });
    }

    var profiles_data = { ...data };
    delete profiles_data["password"];

    console.log("Submitting profile", profiles_data);

    onFinish(profiles_data);
  };
  return (
    <Edit saveButtonProps={{ onClick: handleSubmit(onFinishHandler) }}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("username", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.username}
          helperText={(errors as any)?.username?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="email"
          label={translate("Profiles.fields.Username")}
          name="username"
        />
        <TextField
          {...register("password")}
          error={!!(errors as any)?.Password}
          helperText={(errors as any)?.Password?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={translate("Profiles.fields.Password")}
          name="password"
        />
        <AutoCompleteField
          id="tenant_id"
          control={control}
          resource="tenants"
          field="tenant_id"
          name="Name"
          defaultValue={ProfilesData?.tenant_id}
          label={translate("Profiles.fields.Tenant")}
          error={!!(errors as any)?.tenant_id}
          helperText={(errors as any)?.tenant_id?.message}
        />
        <SelectField
          id="role"
          name="role"
          label="Role"
          margin="normal"
          control={control}
          defaultValue={""}
          helperText={(errors as any)?.role?.message}
          error={!!(errors as any)?.role}
          options={roleOptions}
        />
        <TextField
          {...register("full_name")}
          error={!!(errors as any)?.full_name}
          helperText={(errors as any)?.full_name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Profiles.fields.FullName")}
          name="full_name"
        />
        <TextField
          {...register("job_title")}
          error={!!(errors as any)?.job_title}
          helperText={(errors as any)?.job_title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Profiles.fields.JobTitle")}
          name="job_title"
        />
      </Box>
    </Edit>
  );
};
