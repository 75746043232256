import React from "react";
import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  CreateButton,
  DateField,
  TagField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
} from "@refinedev/core";

import {
    Show,
    TextFieldComponent as TextField,
    NumberField,
} from "@refinedev/mui";

export const LocationsListView: React.FC<DataGridPropsType> = ({locations}) => {
  const translate = useTranslate();

  const columnVisible = {
    id: false
  }

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: translate("Locations.fields.id"),
        type: "number",
        minWidth: 50,
        show: false
      },
      {
        field: "Name",
        flex: 1,
        headerName: translate("Locations.fields.Name"),
        minWidth: 200,
      },
      {
        field: "Floor",
        flex: 1,
        headerName: translate("Locations.fields.Floor"),
        minWidth: 200,
      },
      {
        field: "Units",
        flex: 1,
        headerName: translate("Locations.fields.Units"),
        minWidth: 200,
      },
      {
        field: "Country",
        flex: 1,
        headerName: translate("Locations.fields.Country"),
        minWidth: 200,
      },
      {
        field: "City",
        flex: 1,
        headerName: translate("Locations.fields.City"),
        minWidth: 200,
      },
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [translate],
  );

  return (
      <DataGrid
        {...locations} columns={columns} columnVisibilityModel={columnVisible} autoHeight />
  );
};