import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";
// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
// import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useGetIdentity, useGetLocale, useSetLocale } from "@refinedev/core";
import { HamburgerMenu, RefineThemedLayoutV2HeaderProps } from "@refinedev/mui";
import i18n from "i18n";
import React, { useContext } from "react";
import { ColorModeContext } from "../../contexts/color-mode";

// import {Account} from '../../components/Account'
// import { usePermissions } from "@refinedev/core";

type IUser = {
  id: number;
  full_name: string;
  avatar_url: string;
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  isSticky = true,
}) => {
  const { mode, setMode } = useContext(ColorModeContext);

  const { data: user } = useGetIdentity<IUser>();
  // const { data: perm } = usePermissions();

  const changeLanguage = useSetLocale();
  const locale = useGetLocale();
  const currentLocale = locale();

  // console.log(user?.id, user?.name, user.avatar)
  // console.log(perm)

  return (
    <AppBar position={isSticky ? "sticky" : "relative"}>
      <Toolbar>
        <Stack direction="row" width="100%" alignItems="center">
          <HamburgerMenu />
          <Stack
            direction="row"
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
            gap="16px"
          >
            <FormControl sx={{ minWidth: 64 }}>
              <Select
                disableUnderline
                defaultValue={currentLocale}
                inputProps={{ "aria-label": "Without label" }}
                variant="standard"
                sx={{
                  color: "inherit",
                  "& .MuiSvgIcon-root": {
                    color: "inherit",
                  },
                  "& .MuiStack-root > .MuiTypography-root": {
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  },
                }}
              >
                {[...((i18n as any).languages ?? [])]
                  .sort()
                  .map((lang: string) => (
                    // @ts-ignore
                    <MenuItem
                      selected={currentLocale === lang}
                      key={lang}
                      defaultValue={lang}
                      onClick={() => {
                        changeLanguage(lang);
                      }}
                      value={lang}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {/*<Avatar
                        sx={{
                          width: "24px",
                          height: "24px",
                          marginRight: "5px",
                        }}
                        src={`/images/flags/${lang}.svg`}
                      />*/}
                        <Typography>
                          {lang === "en" ? "English" : "French"}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <IconButton
              color="inherit"
              onClick={() => {
                setMode();
              }}
            >
              {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}
            </IconButton>

            {(user?.avatar_url || user?.full_name) && (
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                justifyContent="center"
              >
                {user?.full_name && (
                  <Typography
                    sx={{
                      display: {
                        xs: "none",
                        sm: "inline-block",
                      },
                    }}
                    variant="subtitle2"
                  >
                    {user?.full_name}
                  </Typography>
                )}
                <Avatar
                  src={"storage/v1/object/public/avatars/" + user?.avatar_url}
                  alt={user?.full_name}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
