import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useMany,
} from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  TagField,
  NumberField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export const TenantsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      select: "id,Name,vendors(id,Name)",
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          {translate("Tenants.fields.Name")}
        </Typography>
        <TextField value={record?.Name} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Tenants.fields.Vendors")}
        </Typography>
        <List dense={true}>
          {record?.vendors?.length &&
            record?.vendors?.map((item: any) => {
              return (
                <ListItem key={item.id}>
                  <ListItemText
                    primary={item.Name}
                    // secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
              );
            })}
        </List>
      </Stack>
    </Show>
  );
};
