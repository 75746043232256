import React from "react";
import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
  MarkdownField,
  EmailField,
  UrlField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
} from "@refinedev/core";

import { DownloadUrlField } from "components/DownloadUrlField";

export const BillsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { dataGridProps } = useDataGrid({
    meta: {
      select: "id,Reference,accounts_id,Docdate,Documents,Metadata",
    },
  });

  const { data: accountsData, isLoading: accountsIsLoading } = useMany({
    resource: "accounts",
    ids: dataGridProps?.rows?.map((item: any) => item?.accounts_id) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
    meta: {
      fields: ["id", "Name"],
    },
  });

  const columnVisible = {
    id: false,
  };

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: translate("Bills.fields.id"),
        type: "number",
        minWidth: 50,
      },

      {
        field: "Reference",
        flex: 1,
        headerName: translate("Bills.fields.Reference"),
        minWidth: 150,
      },
      {
        field: "accounts_id",
        flex: 1,
        headerName: translate("Bills.fields.Accounts"),
        minWidth: 150,
        renderCell: function render({ value }) {
          return (
            accountsData?.data.find((item: any) => item.id === value)?.Name ??
            ""
          );
        },
      },
      {
        field: "Docdate",
        flex: 1,
        headerName: translate("Bills.fields.Docdate"),
        minWidth: 100,
      },

      {
        field: "Documents",
        flex: 1,
        headerName: translate("Bills.fields.Documents"),
        minWidth: 300,
        renderCell: function render({ value }) {
          return <DownloadUrlField value={value} />;
        },
      },
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              {/* <EditButton hideText recordItemId={row.id} /> */}
              <ShowButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [translate, accountsData?.data]
  );

  return (
    <List canCreate={true}>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        columnVisibilityModel={columnVisible}
        autoHeight
      />
    </List>
  );
};
