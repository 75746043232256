import { Edit, useAutocomplete } from "@refinedev/mui";
import {
  Box,
  TextField,
  Autocomplete,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";
import MultipleSelectField from "../../components/MultipleSelectField";

import { vendorTypes } from "./config";

export const VendorsEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    control,
    formState: { errors },
  } = useForm();

  const vendorsData = queryResult?.data?.data;

  const { autocompleteProps: loginsAutocompleteProps } = useAutocomplete({
    resource: "logins",
    defaultValue: vendorsData?.Logins,
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("Name", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.Name}
          helperText={(errors as any)?.Name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Vendors.fields.Name")}
          name="Name"
        />
        <MultipleSelectField
          id="Type"
          name="Type"
          label="Type"
          control={control}
          defaultValue={""}
          variant="outlined"
          margin="normal"
          errors={errors}
          options={vendorTypes}
        />
      </Box>
    </Edit>
  );
};
