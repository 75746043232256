import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useOne,
} from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  NumberField,
  MarkdownField,
  EmailField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

export const ProfilesShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      select: "id,username,tenants(Name),role,full_name,job_title",
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          {translate("Profiles.fields.Username")}
        </Typography>
        <TextField value={record?.username} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Profiles.fields.Tenant")}
        </Typography>
        <TextField value={record?.tenants?.Name} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Profiles.fields.role")}
        </Typography>
        <TextField value={record?.role} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Profiles.fields.FullName")}
        </Typography>
        <TextField value={record?.full_name} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Profiles.fields.JobTitle")}
        </Typography>
        <TextField value={record?.job_title} />
      </Stack>
    </Show>
  );
};
