import React from "react";

import { useState } from "react";

import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  CreateButton,
  DateField,
  TagField,
  ListButton,
} from "@refinedev/mui";

import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
  useList,
} from "@refinedev/core";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AppsIcon from '@mui/icons-material/Apps';

import {LocationsListView} from './components/list-view'
import {LocationsTableView} from './components/table-view'

type View = "card" | "list";

interface ILocation {
  id: number;
  Name: string;
  Floor: number;
  Units: string;
  Country: string;
  City: string;
  Region: string;
  PostalCode: string;
}

export const LocationsList: React.FC<IResourceComponentsProps> = () => {
  const [view, setView] = useState();
  const translate = useTranslate();

  const { dataGridProps } = useDataGrid(
  {
    meta: {
      select: "id,Name,Floor,Units,Country,City,Region,PostalCode",
    },
  }
  );

  const [showClearIcon, setShowClearIcon] = useState("none");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
  };

  const onViewChange = (event, view) => {
    console.log(view)
    setView(view);
  };

  const handleClick = (): void => {
    // TODO: Clear the search input
    console.log("clicked the clear icon...");
  };

  return (
    <div className="page-container">
    <List
      breadcrumb={false}
      headerButtons={({ defaultButtons }) => {
        return (
          <>
          { defaultButtons }
            <FormControl >
              <TextField
                size="small"
                variant="outlined"
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ display: showClearIcon }}
                      onClick={handleClick}
                    >
                      <ClearIcon />
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
            <ToggleButtonGroup
              exclusive
              onChange={onViewChange}
              value={view}
            >
              <ToggleButton size="small" value="list" aria-label="list">
                    <ListAltIcon />
              </ToggleButton>
              <ToggleButton size="small" value="card" aria-label="card">
                    <AppsIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        );
      }}
      contentProps={{
        style: {
            marginTop: "28px",
        },
      }}
      >
      {view === "list" ? (
        <LocationsListView locations={dataGridProps}/>
      ) : (
        <LocationsTableView />
      )}
    </List>
    </div>
  );
};
