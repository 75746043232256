import { Create } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import SelectField from "../../components/SelectField";
import { statusOptions, refreshOptions } from "./config";
import AutoCompleteField from "../../components/AutoCompleteField";

export const LoginsCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Status: "Active",
      Refresh: "Monthly",
      RefreshOffset: 0,
      Name: "",
      vendors_id: null,
      clients_id: null,
      URI: "",
      Username: "",
      Password: "",
      ForwardTo: "",
    },
  });

  const loginsData = queryResult?.data?.data;

  const identity = JSON.parse(localStorage.getItem("profile") as string);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("Name", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.Name}
          helperText={(errors as any)?.Name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Logins.fields.Name")}
          name="Name"
        />
        {(identity.role === "manager" || identity.role === "admin") && (
          <AutoCompleteField // Not working yet.
            control={control}
            resource="clients"
            field="clients_id"
            name="Name"
            defaultValue={loginsData?.clients_id}
            label={translate("Logins.fields.Client")}
            error={!!(errors as any)?.Clients}
            helperText={(errors as any)?.Clients?.message}
          />
        )}
        <AutoCompleteField // Not working yet.
          control={control}
          resource="vendors"
          field="vendors_id"
          name="Name"
          defaultValue={loginsData?.vendors_id}
          label={translate("Logins.fields.Vendor")}
          error={!!(errors as any)?.Vendors}
          helperText={(errors as any)?.Vendors?.message}
        />
        <SelectField
          name="Status"
          label="Status"
          control={control}
          defaultValue={""}
          margin="normal"
          helperText={(errors as any)?.Status?.message}
          error={!!(errors as any)?.Status}
          options={statusOptions}
        />
        <SelectField
          // id="Refresh"
          // {...register("Refresh", {
          //     required: "This field is required",
          // })}
          name="Refresh"
          label="Refresh"
          control={control}
          defaultValue={""}
          // variant="outlined"
          helperText={(errors as any)?.Refresh?.message}
          margin="normal"
          error={!!(errors as any)?.Refresh}
          options={refreshOptions}
        />
        <TextField
          {...register("RefreshOffset", {
            required: "This field is required",
            valueAsNumber: true,
          })}
          error={!!(errors as any)?.RefreshOffset}
          helperText={(errors as any)?.RefreshOffset?.message}
          margin="normal"
          fullWidth
          InputProps={{ inputProps: { min: 0, max: 31 } }}
          InputLabelProps={{ shrink: true }}
          type="number"
          label={translate("Logins.fields.RefreshOffset")}
          name="RefreshOffset"
        />
        <TextField
          {...register("URI", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.URI}
          helperText={(errors as any)?.URI?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          multiline
          label={translate("Logins.fields.URI")}
          name="URI"
        />
        <TextField
          {...register("Username", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.Username}
          helperText={(errors as any)?.Username?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="email"
          label={translate("Logins.fields.Username")}
          name="Username"
        />
        <TextField
          {...register("Password", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.Password}
          helperText={(errors as any)?.Password?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Logins.fields.Password")}
          name="Password"
        />
        <TextField
          {...register("ForwardTo")}
          error={!!(errors as any)?.Name}
          helperText={(errors as any)?.Name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Logins.fields.ForwardTo")}
          name="ForwardTo"
        />
      </Box>
    </Create>
  );
};
