export const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  { value: "Error", label: "Error" },
];

export const invoiceSourceOptions = [
  { value: "Downloader", label: "Downloader" },
  { value: "Email", label: "Email" },
  { value: "Manual", label: "Manual" },
];
