import React from "react";
import { Create } from "@refinedev/mui";
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import SelectField from "../../components/SelectField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AutoCompleteField from "../../components/AutoCompleteField";
import { FieldValues } from "react-hook-form";

import { supabaseClient } from "utility";

import { roleOptions } from "./config";
export const ProfilesCreate: React.FC<IResourceComponentsProps> = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { queryResult, onFinish, redirect },
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      action: "edit",
    },
    defaultValues: {
      username: "",
      tenant_id: 1,
      role: "user",
      full_name: "",
      job_title: "",
      password: "",
    },
  });

  const ProfilesData = queryResult?.data?.data;

  const onFinishHandler = (data: FieldValues) => {
    supabaseClient.auth.admin
      .createUser({
        email: data.username,
        password: data.password,
        email_confirm: true,
        app_metadata: {
          provider: "email",
          providers: ["email"],
          tenant_id: data.tenant_id,
        },
      })
      .then((record) => {
        const user_id = record?.data?.user?.id;

        var profiles_data = { ...data };
        delete profiles_data["password"];

        supabaseClient
          .from("profiles")
          .update(profiles_data)
          .eq("id", user_id)
          .then((res) => {
            redirect("list", "profiles");
          });
      });
  };

  return (
    <Create saveButtonProps={{ onClick: handleSubmit(onFinishHandler) }}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("username", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.username}
          helperText={(errors as any)?.username?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="email"
          label={translate("Profiles.fields.Username")}
          name="username"
        />
        <TextField
          {...register("password")}
          error={!!(errors as any)?.Password}
          helperText={(errors as any)?.Password?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={translate("Profiles.fields.Password")}
          name="password"
        />
        <AutoCompleteField
          id="tenant_id"
          control={control}
          resource="tenants"
          field="tenant_id"
          name="Name"
          defaultValue={ProfilesData?.tenant_id}
          label={translate("Profiles.fields.Tenant")}
          error={!!(errors as any)?.tenant_id}
          helperText={(errors as any)?.tenant_id?.message}
        />
        <SelectField
          id="role"
          name="role"
          label="Role"
          margin="normal"
          control={control}
          defaultValue={""}
          helperText={(errors as any)?.role?.message}
          error={!!(errors as any)?.role}
          options={roleOptions}
        />
        <TextField
          {...register("full_name")}
          error={!!(errors as any)?.full_name}
          helperText={(errors as any)?.full_name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Profiles.fields.FullName")}
          name="full_name"
        />
        <TextField
          {...register("job_title")}
          error={!!(errors as any)?.job_title}
          helperText={(errors as any)?.job_title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Profiles.fields.JobTitle")}
          name="job_title"
        />
      </Box>
    </Create>
  );
};
