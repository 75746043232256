import React from "react";

import {
  Box,
  TextField,
  Autocomplete,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Controller } from "react-hook-form";

// https://blog.logrocket.com/using-material-ui-with-react-hook-form/

interface Props {
  id?: any;
  name?: any;
  label?: any;
  control?: any;
  defaultValue?: string;
  error?: any;
  helperText?: any;
  options?: any;
  variant?: any;
  margin?: any;
  // children,
  props?: any;
}

const SelectField = ({
  name,
  label,
  control,
  defaultValue,
  error,
  helperText,
  options,
  ...props
}: Props) => {
  const labelId = `${name}-label`;
  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{name}</InputLabel>
      <Controller
        control={control}
        defaultValue={defaultValue}
        rules={{ required: "This field is required" }}
        name={name}
        render={({ field }) => {
          return (
            <Select
              {...field}
              labelId={labelId}
              id={name}
              fullWidth
              label={name}
              name={name}
              error={error}
              // helperText={helperText}
            >
              {options.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </FormControl>
  );
};

export default SelectField;
