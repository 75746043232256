import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useOne,
} from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  NumberField,
  MarkdownField,
  EmailField,
  UrlField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

import { DownloadUrlField } from "components/DownloadUrlField";

export const BillsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      select: "id,Reference,accounts(Name),Docdate,Documents,Metadata",
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          {translate("Bills.fields.Reference")}
        </Typography>
        <TextField value={record?.Reference} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Bills.fields.Accounts")}
        </Typography>
        <TextField value={record?.Accounts?.Name} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Bills.fields.Docdate")}
        </Typography>
        <TextField value={record?.Docdate} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Bills.fields.Documents")}
        </Typography>
        {record?.Documents ? (
          <DownloadUrlField value={record?.Documents} />
        ) : (
          "None"
        )}
        <Typography variant="body1" fontWeight="bold">
          {translate("Bills.fields.Metadata")}
        </Typography>
        {record?.Metadata ? (
          <pre>{JSON.stringify(record?.Metadata, undefined, 2)}</pre>
        ) : (
          "Not Available"
        )}
      </Stack>
    </Show>
  );
};
