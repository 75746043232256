import {
  useShow,
  useList,
  IResourceComponentsProps,
  useTranslate,
  useMany,
} from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  TagField,
  NumberField,
} from "@refinedev/mui";

import { Box, Card, Typography } from "@mui/material";

import Stack from "@mui/material/Stack";

interface IVendors {
  id: number;
  Name: string;
}

interface DashboardStatCardProps {
  title?: any;
  value?: any;
  icon?: any;
}

export const DashboardStatCard = ({
  title,
  value,
  icon,
}: DashboardStatCardProps) => {
  const translate = useTranslate();
  return (
    <>
      {/* <Card m={1} pt={1}> */}
      <Card>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Box width="100%" m="30px">
            {icon}
            <TextField fontWeight="bold" value={title} />
          </Box>
          <Box width="100%">
            <TextField fontWeight="bold" value={value} />
          </Box>
        </Stack>
      </Card>
    </>
  );
};

interface VendorCardProps {
  title?: any;
  table?: any;
  icon?: any;
}

export const VendorCard = ({ title, table, icon }: VendorCardProps) => {
  const { data, isLoading, isError } = useList<IVendors>({
    resource: "vendors",
  });

  const products = data?.data ?? [];
  const total = data?.total;

  return <DashboardStatCard title={title} value={total} icon={icon} />;
};

interface ClientCardProps {
  title?: any;
  table?: any;
  icon?: any;
}

export const ClientCard = ({ title, table, icon }: ClientCardProps) => {
  const { data, isLoading, isError } = useList<IVendors>({
    resource: "clients",
  });

  const products = data?.data ?? [];
  const total = data?.total;

  return <DashboardStatCard title={title} value={total} icon={icon} />;
};

interface AccountCardProps {
  title?: any;
  table?: any;
  icon?: any;
}

export const AccountCard = ({ title, table, icon }: AccountCardProps) => {
  const { data, isLoading, isError } = useList<IVendors>({
    resource: "accounts",
  });

  const products = data?.data ?? [];
  const total = data?.total;

  return <DashboardStatCard title={title} value={total} icon={icon} />;
};

interface BillsCardProps {
  title?: any;
  table?: any;
  icon?: any;
}

export const BillsCard = ({ title, table, icon }: BillsCardProps) => {
  const { data, isLoading, isError } = useList<IVendors>({
    resource: "documents",
  });

  const products = data?.data ?? [];
  const total = data?.total;

  return <DashboardStatCard title={title} value={total} icon={icon} />;
};

interface BillMetadataProps {
  bdata?: any;
}

export const BillMetadata = ({ bdata }: BillMetadataProps) => {
  let totals = [];
  var index = 0;
  let d = [10, 20, 30];

  return (
    <Box>
      {/* <BarChart
        xAxis={[{ scaleType: "band", data: ["group A", "group B", "group C"] }]}
        series={[{ data: d }]}
        width={500}
        height={300}
      /> */}
    </Box>
  );
};

export const BillData: React.FC = () => {
  const { data, isLoading, isError } = useList<IVendors>({
    resource: "documents",
    meta: {
      select: "id,Metadata",
    },
  });

  const bills = data?.data ?? [];
  const total = data?.total;

  return (
    <Card>
      <BillMetadata bdata={bills} />
    </Card>
  );
};
