import React from "react";

import { Box, Chip } from "@mui/material";

interface Props {
  selected?: any;
  options?: any;
  helperText?: any;
}

export const ChipField = ({ selected, options }: Props) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected.map((value: any) => {
        const option = options.find((item: any) => item.value === value);
        return (
          <Chip
            key={value}
            label={value}
            style={{ backgroundColor: option?.color }}
          />
        );
      })}
    </Box>
  );
};
