import React from "react";
import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
  MarkdownField,
  EmailField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
} from "@refinedev/core";

import { ChipField } from "../../components/ChipField";
import { PermIdentity } from "@mui/icons-material";

const statusOptions = [
  { value: "Active", color: "#c5e1a5" },
  { value: "Inactive", color: "#ffab9" },
  { value: "Error", color: "#ef9a9a" },
];

export const LoginsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { dataGridProps } = useDataGrid({
    meta: {
      select:
        "id,Name,Status,StatusMessage,Refresh,RefreshOffset,vendors_id,clients_id,accounts(count),ForwardTo", //AccountsCount
    },
  });

  const { data: vendorsData, isLoading: vendorsIsLoading } = useMany({
    resource: "vendors",
    ids: dataGridProps?.rows?.map((item: any) => item?.vendors_id) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
    meta: {
      select: "id,Name",
    },
  });

  const { data: clientsData, isLoading: clientsIsLoading } = useMany({
    resource: "clients",
    ids: dataGridProps?.rows?.map((item: any) => item?.clients_id) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
    meta: {
      select: "id,Name",
    },
  });

  const columnVisible = {
    id: false,
  };

  const columns = React.useMemo<GridColDef[]>(() => {
    const identity = JSON.parse(localStorage.getItem("profile") as string);

    var column_config = [
      {
        field: "id",
        headerName: translate("Logins.fields.id"),
        type: "number",
        minWidth: 50,
      },

      {
        field: "Name",
        flex: 1,
        headerName: translate("Logins.fields.Name"),
        minWidth: 200,
      },
      {
        field: "vendors_id",
        flex: 1,
        headerName: translate("Logins.fields.Vendor"),
        minWidth: 150,
        renderCell: function render({ value }: { value?: any }) {
          return (
            vendorsData?.data.find((item: any) => item.id === value)?.Name ?? ""
          );
        },
      },
      {
        field: "clients_id",
        flex: 1,
        headerName: translate("Logins.fields.Client"),
        minWidth: 150,
        renderCell: function render({ value }: { value?: any }) {
          return (
            clientsData?.data.find((item: any) => item.id === value)?.Name ?? ""
          );
        },
      },
      {
        field: "Status",
        flex: 1,
        headerName: translate("Logins.fields.Status"),
        minWidth: 100,
        renderCell: function render({ value }: { value?: any }) {
          return <ChipField selected={[value]} options={statusOptions} />;
        },
      },
      {
        field: "StatusMessage",
        flex: 1,
        headerName: translate("Logins.fields.StatusMessage"),
        minWidth: 200,
      },
      {
        field: "Refresh",
        flex: 1,
        headerName: translate("Logins.fields.Refresh"),
        minWidth: 100,
      },
      {
        field: "RefreshOffset",
        flex: 1,
        headerName: translate("Logins.fields.RefreshOffset"),
        // type: "number",
        minWidth: 100,
        align: "center",
      },
      {
        field: "accounts",
        flex: 1,
        headerName: translate("Logins.fields.AccountsCount"),
        minWidth: 100,
        renderCell: function render({ value }: { value?: any }) {
          return value[0].count;
        },
      },
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }: { row?: any }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ];
    return column_config.filter(
      (item: any) =>
        item.field !== "clients_id" ||
        identity.role === "manager" ||
        identity.role === "admin"
    ) as GridColDef[];
  }, [translate, vendorsData?.data, clientsData?.data]);

  //

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        columnVisibilityModel={columnVisible}
        autoHeight
      />
    </List>
  );
};
