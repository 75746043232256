import React from "react";
import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  CreateButton,
  DateField,
  TagField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
} from "@refinedev/core";
import { ChipField } from "../../components/ChipField";

const statusOptions = [
  { value: "Active", color: "#c5e1a5" },
  { value: "Inactive", color: "#ffab9" },
  { value: "Error", color: "#ef9a9a" },
];

export const AccountsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { dataGridProps } = useDataGrid({
    meta: {
      select:
        "id,Name,Status,StatusMessage,vendors_id,clients_id,InvoiceSource,Last_Checked,documents(Docdate)", //AccountsCount
    },
  });

  const { data: vendorsData, isLoading: vendorsIsLoading } = useMany({
    resource: "vendors",
    ids: dataGridProps?.rows?.map((item: any) => item?.vendors_id) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
    meta: {
      fields: ["id", "Name"],
    },
  });

  const { data: clientsData, isLoading: clientsIsLoading } = useMany({
    resource: "clients",
    ids: dataGridProps?.rows?.map((item: any) => item?.clients_id) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
    meta: {
      fields: ["id", "Name"],
    },
  });

  const columnVisible = {
    id: false,
  };

  const columns = React.useMemo<GridColDef[]>(
    (): GridColDef[] => {
      const identity = JSON.parse(localStorage.getItem("profile") as string);
      const column_config = [
        {
          field: "id",
          headerName: translate("Accounts.fields.id"),
          type: "number",
          minWidth: 50,
          show: false,
        },
        {
          field: "Name",
          flex: 1,
          headerName: translate("Accounts.fields.Name"),
          minWidth: 200,
        },
        {
          field: "Status",
          flex: 1,
          headerName: translate("Accounts.fields.Status"),
          minWidth: 100,
          renderCell: function render({ value }: { value: any }) {
            return <ChipField selected={[value]} options={statusOptions} />;
          },
        },
        {
          field: "StatusMessage",
          flex: 1,
          headerName: translate("Accounts.fields.StatusMessage"),
          minWidth: 200,
        },
        {
          field: "vendors_id",
          flex: 1,
          headerName: translate("Accounts.fields.Vendor"),
          minWidth: 150,
          renderCell: function render({ value }: { value: any }) {
            return (
              vendorsData?.data.find((item: any) => item.id === value)?.Name ??
              ""
            );
          },
        },
        {
          field: "clients_id",
          flex: 1,
          headerName: translate("Accounts.fields.Client"),
          minWidth: 150,
          renderCell: function render({ value }: { value: any }) {
            return (
              clientsData?.data.find((item: any) => item.id === value)?.Name ??
              ""
            );
          },
        },
        {
          field: "InvoiceSource",
          flex: 1,
          headerName: translate("Accounts.fields.InvoiceSource"),
          minWidth: 200,
        },
        {
          field: "documents",
          flex: 1,
          headerName: translate("Accounts.fields.Last_Docdate"),
          minWidth: 200,
          renderCell: function render({ value }: { value: any }) {
            const sort_result = value.sort((a: any, b: any) =>
              a.Docdate < b.Docdate ? 1 : b.Docdate < a.Docdate ? -1 : 0
            );
            if (sort_result.length > 0) {
              return sort_result[0].Docdate;
            } else {
              return "";
            }
          },
        },
        {
          field: "Last_Checked",
          flex: 1,
          headerName: translate("Accounts.fields.Last_Checked"),
          minWidth: 200,
        },
        {
          field: "actions",
          headerName: translate("table.actions"),
          sortable: false,
          renderCell: function render({ row }: { row: any }) {
            return (
              <>
                <EditButton hideText recordItemId={row.id} />
                <ShowButton hideText recordItemId={row.id} />
              </>
            );
          },
          align: "center",
          headerAlign: "center",
          minWidth: 80,
        },
      ];
      return column_config.filter(
        (item: any) =>
          item.field !== "clients_id" ||
          identity.role === "manager" ||
          identity.role === "admin"
      ) as GridColDef[];
    },
    [translate, vendorsData?.data, clientsData?.data]
    // [translate, vendorsData?.data],
  );

  return (
    <List headerButtons={<CreateButton />}>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        columnVisibilityModel={columnVisible}
        autoHeight
      />
    </List>
  );
};
