import React from "react";
import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
  MarkdownField,
  EmailField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
} from "@refinedev/core";

import { ChipField } from "../../components/ChipField";

const roleOptions = [
  { value: "user", color: "#c5e1a5" },
  { value: "manager", color: "#ffab9" },
  { value: "admin", color: "#ef9a9a" },
];

export const ProfilesList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { dataGridProps } = useDataGrid({
    meta: {
      select: "id,username,full_name,job_title,role,tenant_id", //AccountsCount
    },
  });

  const { data: tenantsData, isLoading: tenantIsLoading } = useMany({
    resource: "tenants",
    ids: dataGridProps?.rows?.map((item: any) => item?.tenant_id) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
    meta: {
      select: "id,Name",
    },
  });

  const columnVisible = {
    id: false,
  };

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: translate("Profiles.fields.id"),
        type: "number",
        minWidth: 50,
      },
      {
        field: "username",
        flex: 1,
        headerName: translate("Profiles.fields.Username"),
        minWidth: 200,
      },
      {
        field: "tenant_id",
        flex: 1,
        headerName: translate("Profiles.fields.Tenant"),
        minWidth: 150,
        renderCell: function render({ value }) {
          return (
            tenantsData?.data.find((item: any) => item.id === value)?.Name ?? ""
          );
        },
      },
      {
        field: "role",
        flex: 1,
        headerName: translate("Profiles.fields.Role"),
        minWidth: 100,
        renderCell: function render({ value }) {
          return <ChipField selected={[value]} options={roleOptions} />;
        },
      },
      {
        field: "full_name",
        flex: 1,
        headerName: translate("Profiles.fields.FullName"),
        minWidth: 200,
      },
      {
        field: "job_title",
        flex: 1,
        headerName: translate("Profiles.fields.JobTitle"),
        minWidth: 200,
      },
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [translate, tenantsData?.data, tenantsData?.data]
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        columnVisibilityModel={columnVisible}
        autoHeight
      />
    </List>
  );
};
