import { useState } from "react";
import { Create, useAutocomplete } from "@refinedev/mui";
import {
  Box,
  TextField,
  Typography,
  ListItem,
  ListItemText,
  Stack,
  Input,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MultipleSelectField from "../../components/MultipleSelectField";

import { vendorTypes } from "./config";

export const VendorsCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const [record, setRecord] = useState({});

  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    control,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useForm();

  const imageInput = watch("images");

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setIsUploadLoading(true);

      const target = event.target;
      const file: File = (target.files as FileList)[0];

      const base64 = await convertBase64(file);

      setValue("images", base64 as string, { shouldValidate: true });

      setIsUploadLoading(false);
    } catch (error) {
      setError("images", { message: "Upload failed. Please try again." });
      setIsUploadLoading(false);
    }
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("Name", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.Name}
          helperText={(errors as any)?.Name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Vendors.fields.Name")}
          name="Name"
        />
        <MultipleSelectField
          id="Type"
          name="Type"
          label="Type"
          control={control}
          defaultValue={""}
          variant="outlined"
          margin="normal"
          errors={errors}
          options={vendorTypes}
        />
        {/* <Stack gap={1}>
                    <>
                        <label htmlFor="images-input">
                            <Input
                                id="images-input"
                                type="file"
                                sx={{ display: "none" }}
                                onChange={onChangeHandler}
                            />
                            <input
                                id="file"
                                {...register("images", {
                                    required: "This field is required",
                                })}
                                type="hidden"
                            />
                            <LoadingButton
                                loading={isUploadLoading}
                                loadingPosition="end"
                                endIcon={<FileUploadIcon />}
                                variant="contained"
                                component="span"
                            >
                                Upload
                            </LoadingButton>
                            <br />
                            {errors.images && (
                                <Typography variant="caption" color="#fa541c">
                                    {errors.images?.message?.toString()}
                                </Typography>
                            )}
                        </label>
                        {imageInput && (
                            <Box
                                component="img"
                                sx={{
                                    maxWidth: 250,
                                    maxHeight: 250,
                                }}
                                src={imageInput.toString()}
                                alt="Post image"
                            />
                        )}
                    </>

                    <Typography variant="body1" fontWeight="bold">
                        {translate("Vendors.fields.Name")}
                    </Typography>
                    <TextField value={record?.Name} />
                    <Typography variant="body1" fontWeight="bold">
                        {translate("Vendors.fields.Type")}
                    </Typography>
                    <TextField value={record?.Type} />
                    <Typography variant="body1" fontWeight="bold">
                        {translate("Vendors.fields.AccountsCount")}
                    </Typography>
                    <TextField value={record?.accounts[0].count} />
                    <Typography variant="body1" fontWeight="bold">
                        {translate("Vendors.fields.Logins")}
                    </Typography>
                    {record?.logins?.length &&
                        (record?.logins?.map((item: any) => {
                            return (
                                <ListItem key={item.id}>
                                    <ListItemText
                                        key={item.id} primary={item.Name}
                                    // secondary={secondary ? 'Secondary text' : null}
                                    />
                                </ListItem>
                            )
                        }))}
                </Stack> */}
      </Box>
    </Create>
  );
};
