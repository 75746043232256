import { Edit, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";

export const ClientsEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    control,
    formState: { errors },
  } = useForm();

  const ClientsData = queryResult?.data?.data;

  const { autocompleteProps: loginsAutocompleteProps } = useAutocomplete({
    resource: "Logins",
    defaultValue: ClientsData?.Logins,
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("Name", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.Name}
          helperText={(errors as any)?.Name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Clients.fields.Name")}
          name="Name"
        />
        <TextField
          {...register("ForwardTo")}
          error={!!(errors as any)?.Name}
          helperText={(errors as any)?.Name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("Clients.fields.ForwardTo")}
          name="ForwardTo"
        />
        {/* <Controller
                    control={control}
                    name="Logins"
                    rules={{ required: "This field is required" }}
                    // eslint-disable-next-line
                    defaultValue={[] as any}
                    render={({ field }) => (
                        <Autocomplete
                            {...loginsAutocompleteProps}
                            {...field}
                            multiple
                            onChange={(_, value) => {
                                field.onChange(
                                    value?.map((item: any) => item?.id ?? item),
                                );
                            }}
                            getOptionLabel={(item) => {
                                return (
                                    loginsAutocompleteProps?.options?.find(
                                        (p) =>
                                            p?.id?.toString() ===
                                            (item?.id ?? item)?.toString(),
                                    )?.title ?? ""
                                );
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined ||
                                option?.id?.toString() ===
                                    (value?.id ?? value)?.toString()
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate("Clients.fields.Logins")}
                                    margin="normal"
                                    variant="outlined"
                                    error={!!(errors as any)?.Logins}
                                    helperText={
                                        (errors as any)?.Logins?.message
                                    }
                                    required
                                />
                            )}
                        />
                    )}
                /> */}
      </Box>
    </Edit>
  );
};
