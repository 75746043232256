import React from "react";
import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  CreateButton,
  DateField,
  TagField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
} from "@refinedev/core";
import { ChipField } from "../../components/ChipField";

import { vendorTypes } from "./config";

export const VendorsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { dataGridProps } = useDataGrid({
    meta: {
      select: "id,Name,Type,accounts(count)", //AccountsCount
    },
  });

  const columnVisible = {
    id: false,
  };

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: translate("Vendors.fields.id"),
        type: "number",
        minWidth: 50,
        show: false,
      },
      {
        field: "Name",
        flex: 1,
        headerName: translate("Vendors.fields.Name"),
        minWidth: 200,
      },
      {
        field: "Type",
        flex: 1,
        headerName: translate("Vendors.fields.Type"),
        minWidth: 200,
        renderCell: function render({ value }) {
          const option = vendorTypes.find((item) => item.value === value);

          return (
            value && (
              <ChipField selected={value?.split(",")} options={vendorTypes} />
            )
          );
        },
      },
      {
        field: "accounts",
        flex: 1,
        headerName: translate("Vendors.fields.AccountsCount"),
        minWidth: 200,
        renderCell: function render({ value }) {
          return value[0].count;
        },
      },
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [translate]
  );

  return (
    <List headerButtons={<CreateButton />}>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        columnVisibilityModel={columnVisible}
        autoHeight
      />
    </List>
  );
};
