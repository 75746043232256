import { Create } from "@refinedev/mui";
import { 
    Box, 
    TextField, 
    Stack 
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { 
    IResourceComponentsProps, 
    useTranslate 
} from "@refinedev/core";

import SelectField from "../../components/SelectField"
import { 
    countryOptions, 
    unitOptions 
} from "./config";
import AutoCompleteField from "../../components/AutoCompleteField";

export const LocationsCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm(
        {
            defaultValues: {
                "Name": "",
                "Floor": 0,
                "Units": "",
                "Country": "Canada",
                "City": null,
                "PostalCode": "",
            }
        }
    );

    const LocationsData = queryResult?.data?.data;

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("Name", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.Name}
                    helperText={(errors as any)?.Name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Name"
                    name="Name"
                />
                <Stack direction = "row" spacing={2}>
                <SelectField
                    sx={{ width: '25%' }}
                    name="Units"
                    label="Units"
                    control={control}
                    defaultValue={""}
                    // variant="outlined"
                    helperText={(errors as any)?.Units?.message}
                    margin="normal"
                    error={!!(errors as any)?.Units}
                    options={unitOptions}
                />
                <TextField
                    {...register("Floor", {
                        required: "This field is required",
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.Floor}
                    helperText={(errors as any)?.Floor?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label="Floor"
                    name="Floor"
                />
                </Stack>
                <SelectField
                    name="Country"
                    label="Country"
                    control={control}
                    defaultValue={""}
                    margin="normal"
                    helperText={(errors as any)?.Country?.message}
                    error={!!(errors as any)?.Country}
                    options={countryOptions}
                />
                <TextField
                    {...register("Address", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.Address}
                    helperText={(errors as any)?.Address?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    multiline
                    label="Address"
                    name="Address"
                />
                <TextField
                    {...register("Region", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.Region}
                    helperText={(errors as any)?.Region?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Region"
                    name="Region"
                />
                <Stack direction = "row" spacing={2}>
                <TextField
                    {...register("City", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.City}
                    helperText={(errors as any)?.City?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="City"
                    name="City"
                />
                <TextField
                    {...register("PostalCode", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.PostalCode}
                    helperText={(errors as any)?.PostalCode?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Postal Code"
                    name="PostalCode"
                />
                </Stack>
            </Box>
        </Create>
    );
};
