import {
  useShow,
  useList,
  IResourceComponentsProps,
  useTranslate,
  useMany,
} from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  TagField,
  NumberField,
} from "@refinedev/mui";

import { Box, Card, Typography } from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import Grid from "@mui/material/Grid"; // Grid version 1
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import {
  VendorCard,
  ClientCard,
  AccountCard,
  BillsCard,
} from "./components/cards";

import { ThemeContext } from "@emotion/react";

export const DashboardPage: React.FC = () => {
  const identity = JSON.parse(localStorage.getItem("profile") as string);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <VendorCard title="Vendors" icon={<BusinessIcon />} />
      </Grid>
      {(identity.role === "manager" || identity.role === "admin") && (
        <Grid item xs={3}>
          <ClientCard title="Clients" icon={<LocalMallIcon />} />
        </Grid>
      )}
      <Grid item xs={3}>
        <AccountCard title="Accounts" icon={<DescriptionIcon />} />
      </Grid>
      <Grid item xs={3}>
        <BillsCard title="Bills" icon={<ReceiptLongIcon />} />
      </Grid>
    </Grid>
  );
};
