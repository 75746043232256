import React from "react";
import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  CreateButton,
  DateField,
  TagField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
  useList,
} from "@refinedev/core";
import { Card, Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Grid from '@mui/material/Grid'; // Grid version 1
import LocationCityIcon from '@mui/icons-material/LocationCity';
import {
  Show,
  TextFieldComponent as TextField,
  NumberField,
} from "@refinedev/mui";
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export const LocationCard: React.FC = ({title, value, icon}) => {
  const translate = useTranslate();
  return (
      <Card m={2} pt={2}>
        <CardHeader
        avatar={icon}
        action={
          <EditButton hideText/>
        }
        title={title}
        />
        <CardContent>
          <Divider />
          <TextField fontWeight="bold" value="Canada" />
       </CardContent>

  </Card>
  );
};

interface IProduct {
  id: number;
  Name: string;
}

export const LocationsTableView: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { data, isLoading, isError } = useList<IProduct>({
    resource: "Clients",
        meta: {
            select: "id,Name"
        },
});

const clients = data?.data?? [];
const total = data?.total;

  return (
    <Box>
    <Grid container>
            {clients.length > 0 ? (
                clients.map((client: IProduct) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        xl={3}
                        key={client.id}
                        sx={{ padding: "8px" }}
                    >
                        <LocationCard
                            title={client.Name}
                            icon={<LocationCityIcon fontSize="large"/>}
                        />
                    </Grid>
                ))
            ) : (
                <Grid
                    container
                    justifyContent="center"
                    padding={3}
                >
                    <TextField value="Empty"/>
                </Grid>
            )}
    </Grid>
    </Box>
  );
};