import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useOne,
} from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  NumberField,
  MarkdownField,
  EmailField,
  DateField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

export const AccountsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      select:
        "id,Name,vendors(Name),clients(Name),Status,StatusMessage,InvoiceSource,Last_Checked,documents(Docdate)",
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const identity = JSON.parse(localStorage.getItem("profile") as string);

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          {translate("Accounts.fields.Name")}
        </Typography>
        <TextField value={record?.Name} />
        {(identity.role === "manager" || identity.role === "admin") && (
          <>
            <Typography variant="body1" fontWeight="bold">
              {translate("Logins.fields.Client")}
            </Typography>
            <TextField value={record?.clients?.Name} />
          </>
        )}
        <Typography variant="body1" fontWeight="bold">
          {translate("Accounts.fields.Vendor")}
        </Typography>
        <TextField value={record?.vendors?.Name} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Accounts.fields.Status")}
        </Typography>
        <TextField value={record?.Status} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Accounts.fields.StatusMessage")}
        </Typography>
        <TextField value={record?.StatusMessage} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Accounts.fields.InvoiceSource")}
        </Typography>
        <TextField value={record?.InvoiceSource} />
        <Typography variant="body1" fontWeight="bold">
          {translate("Accounts.fields.Last_Docdate")}
        </Typography>
        <DateField
          value={
            record?.documents.sort((a: any, b: any) =>
              a.Docdate < b.Docdate ? 1 : b.Docdate < a.Docdate ? -1 : 0
            )[0].Docdate
          }
        />
        <Typography variant="body1" fontWeight="bold">
          {translate("Accounts.fields.Last_Checked")}
        </Typography>
        <DateField value={record?.Last_Checked ?? ""} />
      </Stack>
    </Show>
  );
};
